import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import Section from '../../components1/Section';
import { Text, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto 80px;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const dataConcept = [
    {
        image: 'concept1',
        text1: 'des ingrédients',
        text2: 'locaux frais et de saison, tout ce qu\'il vous faut pour cuisiner',
    },
    {
        image: 'concept2',
        text1: 'des recettes',
        text2: 'simples, familiales et gourmandes qui changent toutes les semaines',
    },
    {
        image: 'concept3',
        text1: 'livrés chez vous',
        text2: 'en point relais ou bien au travail, c\'est à vous de décider',
    },
];

export default () => (
    <StaticQuery
        query={graphql`
            query {
                concept1: file(relativePath: { eq: "index/concept1.png" }) {
                    ...fullWidthImage
                }
                concept2: file(relativePath: { eq: "index/concept2.png" }) {
                    ...squareImage
                }
                concept3: file(relativePath: { eq: "index/concept3.png" }) {
                    ...squareImage
                }
                logoNoir: file(relativePath: { eq: "logos/logo-noir.png" }) {
                    childImageSharp {
                        fixed(height: 60) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
            }
        `}
        render={data => (
            <>
                <Section
                    id="section1"
                    style={{
                        marginTop: 15,
                        marginBottom: 30,
                    }}
                >
                    <Title
                        style={{
                            flex: 1,
                            textAlign: 'center',
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1.5rem !important;
                            }
                        `}
                    >Le Concept CABADICI</Title>
                    <GridContainer>
                        {
                            dataConcept.map((concept, idx) => {
                                return(
                                    <React.Fragment key={idx.toString()}>
                                        <Image
                                            style={{
                                                gridColumn: idx+1,
                                                gridRow: 1,
                                                justifySelf: 'center',
                                                width: (idx === 0 ? '60%' : '90%'),
                                                flex: 0,
                                                padding: '10px 0',
                                            }}
                                            css={`
                                                @media only screen and (min-width: 599px) {
                                                    width: ${idx === 0 ? '60%' : '80%'} !important;
                                                }
                                            `}
                                            imgStyle={{
                                                objectFit: 'contain',
                                            }}
                                            fluid={data[`concept${idx+1}`].childImageSharp.fluid}
                                            alt="concept1"
                                        />
                                        <Text
                                            special
                                            style={{
                                                gridColumn: idx+1,
                                                gridRow: 2,
                                                fontSize: '2.5rem',
                                                alignSelf: 'start',
                                                padding: 0,
                                                textAlign: 'center'
                                            }}
                                            css={`
                                                @media only screen and (max-width: 599px) {
                                                    font-size: 1.5rem !important;
                                                }
                                            `}
                                        >
                                            {concept.text1}
                                        </Text>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'start'
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    gridColumn: idx+1,
                                                    gridRow: 3,
                                                    maxWidth: '180px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    textAlign: 'justify',
                                                    textAlignLast: 'center',
                                                    fontSize: '0.875rem',
                                                    paddingTop: 0
                                                }}
                                                css={`
                                                    @media only screen and (max-width: 599px) {
                                                        font-size: 0.75rem !important;
                                                        text-align: center !important;
                                                    }
                                                `}
                                            >
                                                {concept.text2}
                                            </Text>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </GridContainer>
                    <FlexDiv
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                flex-direction: column !important;
                            }
                        `}
                    >
                        <Image
                            fixed={data.logoNoir.childImageSharp.fixed}
                            alt="logo-noir"
                        />
                        <Text
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 20,
                                textAlign: 'center',
                                paddingLeft: 20,
                            }}
                            css={`
                                font-family: ${props => props.theme.font.title}
                                @media only screen and (max-width: 599px) {
                                    padding-left: 0 !important;
                                    padding-top: 20px !important;
                                }
                            `}
                        >
                            {"s'occupe des courses et du menu, vous cuisinez sans y penser !"}
                        </Text>
                    </FlexDiv>
                </Section>
            </>
        )}
    />
);
