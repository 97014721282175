import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import withTheme from '../../theme';

import Section from '../../components1/Section';
import { Text, Title } from '../../components1/Texts';
import LinedTitle from '../../components1/LinedTitle';
import FlexDiv from '../../components1/FlexDiv';
import { CabadiciButton } from '../../components1/buttons';
import RecipesSlider from '../RecipesSlider'

const Section3 = ({ theme, children }) => (
    <>
        <FlexDiv>
            <LinedTitle
                style={{
                    padding: 0
                }}
            >
                <Title
                    style={{
                        padding: '10px 50px',
                    }}
                >
                    Le prix
                </Title>
            </LinedTitle>
        </FlexDiv>
        <div
            style={{
                backgroundColor: theme.color.beigeFaible,
                alignItems: 'stretch'
            }}
        >
            <Section>
                <FlexDiv
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <RecipesSlider />
                </FlexDiv>
                <FlexDiv
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Text
                        style={{
                            fontSize: '1.125rem',
                            fontFamily: theme.font.title,
                            paddingBottom: 20,
                            textAlign: 'center',
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 0.875rem !important;
                            }
                        `}
                    >
                        Vous choisissez chaque semaine
                        <br/>
                        combien de recettes vous souhaitez recevoir.
                        <br/>
                        Vous pouvez faire une pause quand vous voulez !
                    </Text>
                </FlexDiv>
                <FlexDiv
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    {
                        children || <Link style={{ textDecoration: 'none' }} to="/concept">
                            <CabadiciButton color="jauneFort">en savoir plus</CabadiciButton>
                        </Link>
                    }
                </FlexDiv>
            </Section>
        </div>
    </>
);

Section3.propTypes = {
    children: PropTypes.element,
    theme: PropTypes.shape().isRequired
};

export default withTheme(Section3);
