import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

const ContentBackgroundImage = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            contentBackgroundImageFluid: file(relativePath: { eq: "index/contenu-landscape.jpg" }) {
                ...fullWidthImage
            }
        }
    `);

    return (
        <BackgroundImage
            fluid={data.contentBackgroundImageFluid.childImageSharp.fluid}
            style={{
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
            }}
            loading="eager"
            fadeIn={false}
            css={`
                @media only screen and (max-width: 599px) {
                    min-height: 135px !important;
                }
            `}
        >
            {children}
        </BackgroundImage>
    );
};

ContentBackgroundImage.propTypes = {
    children: PropTypes.element.isRequired
};

export default ContentBackgroundImage;
