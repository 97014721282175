import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import withTheme from '../../theme';

import Section from '../../components1/Section';
import { Text, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';
import LinedTitle from '../../components1/LinedTitle';
import { CabadiciButton } from '../../components1/buttons';
import Separator from '../../components1/Separator';

const Section7 = ({ theme }) => (
    <Section id="section7">
        <FlexDiv
            style={{ justifyContent: 'center'}}
        >
            <img
                style={{maxWidth:'100%', height:'auto'}}
                src={logoText}
                alt="commander"
            />
        </FlexDiv>
    </Section>
);

export const Section7Void = () => {
    return (
        <Section style={{
            height: 200
        }}/>
    );
}

Section7.propTypes = {
    theme: PropTypes.shape().isRequired
};

export default withTheme(Section7);
