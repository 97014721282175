import React from 'react';
import PropTypes from 'prop-types';

import { Text, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';
import ContentBackgroundImage from '../../components1/ContentBackgroundImage';

const dataContenu = [
    {
        text1: 'Tous les ingrédients nécessaires',
        text2: [
            'pour cuisiner nos recettes',
            'et pré-dosés pour 4 personnes',
            '(poisson/viande, légumes, épices...).',
            'Frais, locaux, de saison.',
        ],
    },
    {
        text1: 'Des fiches recettes simples',
        text2: [
            'pour cuisiner en 30 minutes',
            'des plats familiaux',
            'gourmands et originaux.',
        ],
    },
    {
        text1: 'Un cabas réutilisable,',
        text2: [
            'une pochette isotherme',
            'et une plaquette de gel glacé',
            'pour garder tout cela au frais !',
        ],
    },
];


const Section2 = () => (
    <>
        <FlexDiv
            css={`background-color: ${props => props.theme.color.vertFort};`}
        >
            <Title
                style={{
                    flex: 1,
                    textAlign: 'center',
                    color: '#fff',
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1.25rem !important;
                        padding: 5px;
                    }
                `}
            >
                {'Le contenu d\'un CABADICI'}
            </Title>
        </FlexDiv>
        <ContentBackgroundImage
            id="section2"
        >
            <FlexDiv
                style={{
                    // backgroundColor: '#fff',
                    // opacity: 0.6,
                    padding: '20px 0',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                {
                    dataContenu.map((contenu, index) => (
                        <FlexDiv
                            key={index.toString()}
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: 600,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 0.875rem !important;
                                    }
                                `}
                            >
                                {contenu.text1}
                            </Text>
                            {
                                contenu.text2.map((text, index) => (
                                    <Text
                                        key={index.toString()}
                                        css={`
                                            @media only screen and (max-width: 599px) {
                                                font-size: 0.75rem !important;
                                            }
                                        `}
                                    >
                                        {text}
                                    </Text>
                                ))
                            }
                            {
                                (index < 2) && (
                                    <Text
                                        style={{
                                            padding: 5,
                                        }}
                                        css={`
                                            @media only screen and (max-width: 599px) {
                                                font-size: 0.875rem !important;
                                            }
                                        `}
                                    >
                                        +
                                    </Text>
                                )
                            }
                        </FlexDiv>
                    ))
                }
            </FlexDiv>
        </ContentBackgroundImage>
        <Title
            style={{
                flex: 1,
                textAlign: 'center',
                textTransform: 'none',
                padding: '10px 0'
            }}
            css={`
                @media only screen and (max-width: 599px) {
                    font-size 1.125rem !important;
                }
            `}
        >
            Vous consommez local sans y penser !
        </Title>
    </>
);

export default Section2;
