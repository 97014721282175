import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import withTheme from '../../theme';

import Section from '../../components1/Section';
import { Text, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';
import LinedTitle from '../../components1/LinedTitle';
import { CabadiciButton } from '../../components1/buttons';
import Separator from '../../components1/Separator';

const ideeData = [
    {
        text: 'circuits courts'
    },
    {
        text: 'local'
    },
    {
        text: 'familial'
    },
    {
        text: 'responsable'
    },
    {
        text: 'pratique'
    },
]

const Section6 = ({ theme }) => (
    <StaticQuery
        query={graphql`
            query {
                recette: file(relativePath: { eq: "ici-ou/RECETTE.png" }) {
                    childImageSharp {
                        fixed(height: 60) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
                local: file(relativePath: { eq: "ici-ou/LOCAL.png" }) {
                    childImageSharp {
                        fixed(height: 60) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
                liste: file(relativePath: { eq: "ici-ou/LISTE.png" }) {
                    childImageSharp {
                        fixed(height: 60) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
                bio: file(relativePath: { eq: "ici-ou/BIO.png" }) {
                    childImageSharp {
                        fixed(height: 60) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
                caddie: file(relativePath: { eq: "ici-ou/CADDIE.png" }) {
                    childImageSharp {
                        fixed(height: 60) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
            }
        `}
        render={data => (
            <>
                <Section id="section6">
                    <Title style={{
                        flex: 1,
                        textAlign: 'center',
                        paddingTop: 30,
                        paddingBottom: 20,
                    }}>
                        {'D\'ici mais d\'où ?'}
                    </Title>
                    <FlexDiv
                        style={{
                            paddingTop: 20,
                            paddingBottom: 20,
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                        }}
                    >
                        {
                            ideeData.map(({text}, index) => {
                                return (
                                    <FlexDiv
                                        key={index.toString()}
                                        style={{
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            padding: 10,
                                        }}
                                        css={`
                                            @media only screen and (max-width: 599px) {
                                                max-width: 6rem;
                                            }
                                        `}
                                    >
                                        <Image
                                            imgStyle={{
                                                objectFit: 'contain',
                                            }}
                                            fixed={data.recette.childImageSharp.fixed}
                                            alt="idee1"
                                        />
                                        <Text
                                            special
                                            style={{
                                                textAlign: 'center',
                                                fontSize: 40
                                            }}
                                        >
                                            {text}
                                        </Text>
                                    </FlexDiv>
                                )
                            })
                        }
                    </FlexDiv>
                    <FlexDiv style={{justifyContent: 'center'}}>
                        <Link style={{ textDecoration: 'none' }} to="/ici-ou">
                            <CabadiciButton color="vertFort">en savoir plus</CabadiciButton>
                        </Link>
                    </FlexDiv>
                </Section>
                <Separator
                    strokeColor="vertFaible"
                    strokeWidth="3px"
                />
            </>
        )}
    />
);

Section6.propTypes = {
    theme: PropTypes.shape().isRequired
};

export default withTheme(Section6);
