import React from 'react';
// import { Link } from 'gatsby';
import { Layout } from '../components1/layout';

import Section1 from '../containers/sections/1-concept';
import Section2 from '../containers/sections/2-contenu';
import Section3 from '../containers/sections/3-prix';
import Section4 from '../containers/sections/4-recettes';
import Section5 from '../containers/sections/5-livraisons';
import Section6 from '../containers/sections/6-idee';
import { Section7Void } from '../containers/sections/7-commande';

const IndexPage = ({ location }) => {
    const {doOpenConnectForm} = typeof location.state !== 'undefined' && location.state ? location.state : {};

    return (
        <Layout isIndex doOpenConnectForm={doOpenConnectForm}>
            {
                ({ openConnectForm }) => (
                    <>
                        <Section1 />
                        <Section2 />
                        <Section3 />
                        <Section4 isIndex />
                        <Section5 isIndex openConnectForm={openConnectForm} />
                        {/*<Section6 />*/}
                        {/*<Section7Void />*/}
                    </>
                )
            }
        </Layout>
    )};

export default IndexPage;
